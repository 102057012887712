import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonMenuButton,
	IonPage,
	IonRow,
	IonTitle,
	IonToolbar,
	useIonAlert,
	useIonLoading,
} from '@ionic/react';
import { checkmarkOutline, mailUnreadOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
	getAllRegisteredAgentsRealtime,
	sendConfirmationEmail,
} from '../../../services/firestore';
import './RegisteredAgentsList.scss';
const RegisteredAgentsList: React.FC = () => {
	const [agents, setAgents] = useState<any[]>([]);
	const [presentAlert] = useIonAlert();
	const [presentLoader, dismissLoader] = useIonLoading();
	const history = useHistory();

	useEffect(() => {
		getAllRegisteredAgentsRealtime((snapshot) => {
			console.log('get registered agents');
			snapshot.docChanges().forEach((change: any) => {
				console.log('a ' + JSON.stringify(change.doc.data()));
				setAgents((prevState) => {
					const pState = [...prevState];
					const currentDocument = change.doc.data();
					currentDocument.id = change.doc.id;
					console.log('agent ' + JSON.stringify(currentDocument));
					if (change.type === 'added') {
						if (pState.length > 0) {
							const currentDocTime = currentDocument.creationDate
								.toDate()
								.getTime();
							const firstDocTime = pState[0].creationDate.toDate().getTime();

							if (currentDocTime > firstDocTime) {
								pState.unshift(currentDocument);
							} else {
								pState.push(currentDocument);
							}
						} else {
							pState.push(currentDocument);
						}

						return pState;
					}

					if (change.type === 'modified') {
						const agents = pState.map((agent) => {
							if (agent.email === change.doc.data().email) {
								const doc = change.doc.data();
								doc.id = change.doc.id;
								return doc;
							}
							return agent;
						});

						return agents;
					}

					return prevState;
				});
			});
		});
	}, []);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='primary'>
					<IonTitle>Agenti Registrati</IonTitle>
					<IonButtons slot='start'>
						<IonBackButton></IonBackButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen className='super-admin-registered-agent-list'>
				<IonGrid>
					<IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							{agents?.length === 0 && (
								<div className='no-data ion-padding ion-margin ion-text-center'>
									Non sono presenti agenti registrati
								</div>
							)}
							<IonList className='agents-list-wrapper'>
								{agents?.map((agent) => {
									return (
										<div
											className='item-wrapper'
											key={agent.creationDate.seconds}
										>
											<p className='header-text'>{agent.name}</p>
											<IonItem
												key={agent.creationDate.seconds}
												lines='full'
												className='list-item'
											>
												<IonLabel className='name'>
													{/* <p className='text'>
											<span className='amount'>Status account </span>
											{b.isEmailSent
												? 'Email di conferma inviata'
												: 'Email di conferma non inviata'}
										</p> */}
													<p className='text'>
														<span className='amount'>Email </span>
														{agent.email}
													</p>
													<p className='text'>
														<span className='amount'>Tel. </span>
														{agent.telephone}
													</p>
													<small className='text'>
														{new Intl.DateTimeFormat('it').format(
															agent.creationDate.toDate()
														)}
													</small>
												</IonLabel>
											</IonItem>
											{agent.state !== 'ENABLED' && (
												<IonButton
													color={agent.isEmailSent ? 'success' : 'primary'}
													className='standard-btn'
													disabled={agent.isEmailSent}
													onClick={async () => {
														presentLoader('Invio email...');
														try {
															await sendConfirmationEmail(agent.email, 'a');
															dismissLoader();
															presentAlert(
																'Mail di conferma inviata correttamente'
															);
														} catch (e) {
															dismissLoader();
															presentAlert(
																"Errore durante l'invio della mail di conferma"
															);
														} finally {
														}
													}}
												>
													{!agent.isEmailSent
														? 'Invia email di attivazione'
														: 'In attesa di conferma'}
													<IonIcon
														slot='start'
														icon={mailUnreadOutline}
													></IonIcon>
												</IonButton>
											)}

											{agent.state === 'ENABLED' && (
												<IonItem color='success'>
													<IonLabel>Agente abilitato</IonLabel>
													<IonIcon icon={checkmarkOutline} slot='start' />
												</IonItem>
											)}
											<hr />
										</div>
									);
								})}
							</IonList>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default RegisteredAgentsList;
