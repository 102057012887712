import {
	IonButtons,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonMenuButton,
	IonPage,
	IonRow,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { bagCheckOutline, cashOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import {
	getCustomerTransactions,
	getCustomerTransactionsRealtime,
} from '../../services/firestore';
import { getUserData } from '../../services/storage';
import './CustomerTransactions.scss';

const CustomerTransactions: React.FC = () => {
	const [transactions, setTransactions] = useState<any[]>([]);

	useEffect(() => {
		getCustomerTransactionsRealtime((snapshot) => {
			snapshot.docChanges().forEach((change: any) => {
				setTransactions((prevState) => {
					const pState = [...prevState];

					if (pState.length > 0) {
						const doc = change.doc.data();
						const currentDocTime = doc.creationDate.toDate().getTime();
						const firstDocTime = pState[0].creationDate.toDate().getTime();

						if (currentDocTime > firstDocTime) {
							pState.unshift(change.doc.data());
						} else {
							pState.push(change.doc.data());
						}
					} else {
						pState.push(change.doc.data());
					}

					return pState;
				});
			});
		});
	}, []);
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>Le tue transazioni</IonTitle>
					<IonButtons slot='start'>
						<IonMenuButton autoHide={false} menu='customerMenu'></IonMenuButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<IonGrid>
					<IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							{transactions?.length === 0 && (
								<p className='no-transactions ion-text-center ion-margin-top'>
									Non hai ancora effettuato nessuna transazione
								</p>
							)}
							<IonList className='transaction-list-wrapper'>
								{transactions?.map((t) => {
									return (
										<div className='item-wrapper' key={t.creationDate.seconds}>
											<p className='header-text'>{t.businessName}</p>
											<IonItem
												key={t.creationDate.seconds}
												lines='full'
												className='list-item'
											>
												<IonIcon
													slot='start'
													icon={
														t.isWithdrawalFromCustomer
															? cashOutline
															: bagCheckOutline
													}
													className='icon'
												></IonIcon>
												<IonLabel className='description'>
													<p className='text'>
														<span className='amount'>
															{t.isWithdrawalFromCustomer
																? 'Utilizzato credito Jackshop'
																: 'Spesa'}{' '}
														</span>
														{t.amountSpent} €
													</p>
													<small className='text'>
														{new Intl.DateTimeFormat('it').format(
															t.creationDate.toDate()
														)}
													</small>
												</IonLabel>
											</IonItem>
										</div>
									);
								})}
							</IonList>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default CustomerTransactions;
