import React from 'react';
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonImg,
	IonText,
	IonItem,
	IonLabel,
	IonIcon,
	useIonToast,
	IonButton,
	useIonRouter,
} from '@ionic/react';
import { useParams, useLocation } from 'react-router-dom';
import { arrowBack, trash } from 'ionicons/icons';
import { BusinessEvent } from './types';
import { deleteBusinessEvent } from '../../services/firestore';

const EventDetail: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const location = useLocation<{ event: BusinessEvent; isMyEvent: boolean }>();
	const event = location.state?.event;
	const isMyEvent = location.state?.isMyEvent || false;
	const [presentToast] = useIonToast();
	const router = useIonRouter();
	if (!event) {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonTitle>Evento Non Trovato</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent className='ion-padding'>
					<IonText>Evento non trovato.</IonText>
				</IonContent>
			</IonPage>
		);
	}
	const handleDeleteEvent = async (eventId: string) => {
		try {
			await deleteBusinessEvent(eventId);

			presentToast({
				message: 'Event deleted successfully',
				duration: 2000,
				position: 'top',
			});
			router;
		} catch (error) {
			console.error('Error deleting event:', error);
			presentToast({
				message: 'Error deleting event',
				duration: 2000,
				position: 'top',
			});
		}
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Dettaglio Evento {event.title}</IonTitle>
					<IonItem
						routerLink={isMyEvent ? '/business-events' : '/events'}
						routerDirection='back'
					>
						<IonLabel>
							{isMyEvent ? 'Torna ai Miei Eventi' : 'Torna agli Eventi'}
						</IonLabel>
						<IonIcon slot='start' icon={arrowBack}></IonIcon>
					</IonItem>
				</IonToolbar>
			</IonHeader>
			<IonContent className='ion-padding'>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						width: '100%',
						alignItems: 'center',
					}}
				>
					<IonImg
						style={{ width: '400px', height: '400px' }}
						src={event.image}
					/>
				</div>
				<IonText color='light'>
					<h2>{event.title}</h2>
					<p>{event.description}</p>
					<p>
						<strong>Categoria:</strong> {event.category}
					</p>
					<p>
						<strong>Città:</strong> {event.city}
					</p>
					<p>
						<strong>Data di Inizio:</strong>{' '}
						{new Date(event.startDate).toLocaleString()}
					</p>
					<p>
						<strong>Data di Fine:</strong>{' '}
						{new Date(event.endDate).toLocaleString()}
					</p>
				</IonText>
				{isMyEvent && (
					<IonButton
						fill='clear'
						color='danger'
						onClick={(e) => {
							e.stopPropagation();
							handleDeleteEvent(event.id);
						}}
					>
						<IonIcon slot='icon-only' icon={trash}></IonIcon>
					</IonButton>
				)}
			</IonContent>
		</IonPage>
	);
};

export default EventDetail;
