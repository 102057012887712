import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonPage,
	IonTitle,
	IonToolbar,
	useIonAlert,
	useIonLoading,
} from '@ionic/react';
import {
	cashOutline,
	checkmarkOutline,
	home,
	mailUnreadOutline,
} from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
	getAllRegisteredBusinessesRealtime,
	sendConfirmationEmail,
} from '../../../services/firestore';
import './RegisteredBusinessesList.scss';

const RegisteredBusinessesList: React.FC = () => {
	const [businesses, setBusinesses] = useState<any[]>([]);
	const [presentAlert] = useIonAlert();
	const [presentLoader, dismissLoader] = useIonLoading();
	const history = useHistory();

	useEffect(() => {
		getAllRegisteredBusinessesRealtime((snapshot) => {
			snapshot.docChanges().forEach((change: any) => {
				setBusinesses((prevState) => {
					const pState = [...prevState];
					const currentDocument = change.doc.data();
					currentDocument.id = change.doc.id;
					if (change.type === 'added') {
						if (pState.length > 0) {
							const currentDocTime = currentDocument.creationDate
								.toDate()
								.getTime();
							const firstDocTime = pState[0].creationDate.toDate().getTime();

							if (currentDocTime > firstDocTime) {
								pState.unshift(currentDocument);
							} else {
								pState.push(currentDocument);
							}
						} else {
							pState.push(currentDocument);
						}

						return pState;
					}

					if (change.type === 'modified') {
						const businesses = pState.map((business) => {
							if (business.email === change.doc.data().email) {
								const doc = change.doc.data();
								doc.id = change.doc.id;
								return doc;
							}
							return business;
						});

						return businesses;
					}

					return prevState;
				});
			});
		});
	}, []);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>Attività registrate</IonTitle>
					<IonButtons slot='start'>
						<IonBackButton />
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen className='super-admin-registered-businesses-list'>
				<IonList className='business-list-wrapper'>
					{businesses?.length === 0 && (
						<div className='no-data ion-padding ion-margin ion-text-center'>
							Non sono presenti attività registrate
						</div>
					)}
					{businesses?.map((b) => {
						return (
							<div className='item-wrapper' key={b.creationDate.seconds}>
								<p className='header-text'>{b.businessName}</p>
								<IonItem
									key={b.creationDate.seconds}
									lines='full'
									className='list-item'
								>
									<IonLabel className='name'>
										{/* <p className='text'>
											<span className='amount'>Status account </span>
											{b.isEmailSent
												? 'Email di conferma inviata'
												: 'Email di conferma non inviata'}
										</p> */}
										<p className='text'>
											<span className='amount'>Email </span>
											{b.email}
										</p>
										<p className='text'>
											<span className='amount'>Titolare </span>
											{b.businessOwner}
										</p>
										<p className='text'>
											<span className='amount'>Città </span>
											{b.city}
										</p>
										<p className='text'>
											<span className='amount'>Indirizzo </span>
											{b.address}
										</p>
										<p className='text'>
											<span className='amount'>Tel. </span>
											{b.telephone}
										</p>
										<p className='text'>
											<span className='amount'>P. IVA </span>
											{b.vatNumber}
										</p>
										<p className='text'>
											<span className='amount'>Credito </span>
											{b.credit ?? 0} €
										</p>
										<small className='text'>
											{new Intl.DateTimeFormat('it').format(
												b.creationDate.toDate()
											)}
										</small>
									</IonLabel>
								</IonItem>
								{b.state !== 'ENABLED' && (
									<IonButton
										color={b.isEmailSent ? 'success' : 'primary'}
										className='standard-btn'
										disabled={b.isEmailSent}
										onClick={async () => {
											presentLoader('Invio email...');
											try {
												await sendConfirmationEmail(b.email, 'b');
												dismissLoader();
												presentAlert('Mail di conferma inviata correttamente');
											} catch (e) {
												dismissLoader();
												presentAlert(
													"Errore durante l'invio della mail di conferma"
												);
											} finally {
											}
										}}
									>
										{!b.isEmailSent
											? 'Invia email di attivazione'
											: 'In attesa di conferma'}
										<IonIcon slot='start' icon={mailUnreadOutline}></IonIcon>
									</IonButton>
								)}

								<IonButton
									className='standard-btn'
									onClick={() => {
										history.push(
											`/super-admin-update-business-credit?businessName=${
												b.businessName
											}&currentCredit=${b.credit ?? 0}&businessUid=${b.id}`
										);
									}}
								>
									Aggiorna credito attività
									<IonIcon slot='start' icon={cashOutline}></IonIcon>
								</IonButton>

								{b.state === 'ENABLED' && (
									<IonItem color='success'>
										<IonLabel>Attività abilitata</IonLabel>
										<IonIcon icon={checkmarkOutline} slot='start' />
									</IonItem>
								)}
								<hr />
							</div>
						);
					})}
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default RegisteredBusinessesList;
