import {
	IonApp,
	IonIcon,
	IonLabel,
	IonRouterOutlet,
	IonTabBar,
	IonTabButton,
	IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import {
	bodyOutline,
	businessOutline,
	calendarOutline,
	home,
	homeOutline,
	list,
	logoEuro,
	qrCode,
} from 'ionicons/icons';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import AgentMenu from './AgentMenu';
import { useAppSelector } from './app/hooks';
import BusinessMenu from './BusinessMenu';
import CustomerMenu from './CustomerMenu';
import { authStateChanged } from './features/auth/authSlice';
import { UserType } from './features/auth/types';
import InfluencerMenu from './InfluencerMenu';
import AgentHome from './pages/Agent/agentHome/AgentHome';
import AgentRegisteredBusinessList from './pages/Agent/agentRegisteredBusinessList/AgentRegisteredBusinessList';
import AgentRegisteredInfluencerList from './pages/Agent/agentRegisteredInfluencerList/AgentRegisteredInfluencerList';
import AgentRegisterNewBusiness from './pages/Agent/agentRegisterNewBusiness/AgentRegisterNewBusiness';
import AgentRegisterNewInfluencer from './pages/Agent/agentRegisterNewInfluencer/AgentRegisterNewInfluencer';
import BusinessHome from './pages/Business/businessHome/BusinessHome';
import BusinessScanCustomerQR from './pages/Business/businessScanCustomerQR/BusinessScanCustomerQR';
import BusinessTransactionImport from './pages/Business/businessTransactionImport/BusinessTransactionImport';
import BusinessTransactions from './pages/Business/businessTransactions/BusinessTransactions';
import ChooseTransactionType from './pages/Business/chooseTransactionType/ChooseTransactionType';
import { ConfirmAccountRegistration } from './pages/confirmAccountRegistration/ConfirmAccountRegistration';
import CustomerHome from './pages/customerHome/CustomerHome';
import CustomerRegistration from './pages/customerRegistration/CustomerRegistration';
import CustomerTransactions from './pages/customerTransactions/CustomerTransactions';
import InfluencerHome from './pages/Influencer/influencerHome/InfluencerHome';
import InfluencerTransactions from './pages/Influencer/influencerTransactions/InfluencerTransactions';
import Login from './pages/login/Login';
import RegisteredAgentsList from './pages/SuperAdmin/registeredAgentsList/RegisteredAgentsList';
import RegisteredBusinessesList from './pages/SuperAdmin/registeredBusinessesList/RegisteredBusinessesList';
import RegisteredInfluencersList from './pages/SuperAdmin/registeredInfluencersList/RegisteredInfluencersList';
import SuperAdminCreateAgentAccount from './pages/SuperAdmin/superAdminCreateAgentAccount/SuperAdminCreateAgentAccount';
import SuperAdminHome from './pages/SuperAdmin/superAdminHome/SuperAdminHome';
import SuperAdminUpdateBusinessCredit from './pages/SuperAdmin/superAdminUpdateBusinessCredit/SuperAdminUpdateBusinessCredit';

import RegisteredBusinesses from './pages/RegisteredBusinesses/RegisteredBusinesses';
import { verifyAuthState } from './services/firestore';
import { setUserData } from './services/storage';
import SuperAdminMenu from './SuperAdminMenu';
/* Theme variables */
import './theme/variables.css';
import { UserData } from './types';
import CustomerWinList from './pages/customerWinsList/CustomerWinList';
import RegisteredBusinessesOnline from './pages/RegisteredBusinessesOnline/RegisteredBusinessesOnline';
import ResetPasswordRequest from './pages/resetPasswordRequest/ResetPasswordRequest';
import EventsList from './pages/Events/EventsList';
import EventDetail from './pages/Events/EventDetail';
import AddEvent from './pages/Events/AddEvent';
import { ConfirmResetPassword } from './pages/confirmResetPassword/ConfirmResetPassword';
import BusinessEvents from './pages/Business/businessEvents/BusinessEvents';

const App = () => {
	const dispatch = useDispatch();

	const userType = useAppSelector((state) => state.authSlice.userType);

	verifyAuthState(async (user) => {
		const token = await user?.getIdTokenResult(true);

		const userData: UserData = {
			name: token?.claims.name as string,
			userUID: token?.claims.user_id as string,
		};

		await setUserData(userData);

		if (token) {
			if (token.claims) {
				// Business account
				if (token.claims.businessAccount) {
					dispatch(
						authStateChanged({
							userType: UserType.BUSINESS,
							name: userData.name,
							userUID: userData.userUID,
						})
					);
				}

				// Influencer account
				if (token.claims.influencerAccount) {
					dispatch(
						authStateChanged({
							userType: UserType.INFLUENCER,
							name: userData.name,
							userUID: userData.userUID,
						})
					);
				}

				// Agent account
				if (token.claims.agentAccount) {
					userData.isAgent = true;
					dispatch(
						authStateChanged({
							userType: UserType.AGENT,
							name: userData.name,
							userUID: userData.userUID,
						})
					);
				}

				// Super admin account
				if (token.claims.superAdminAccount) {
					userData.isSuperAdmin = true;
					dispatch(
						authStateChanged({
							userType: UserType.SUPERADMIN,
							name: userData.name,
							userUID: userData.userUID,
						})
					);
				}

				// Super admin account
				if (token.claims.customerAccount) {
					userData.isCustomerAccount = true;
					dispatch(
						authStateChanged({
							userType: UserType.CUSTOMER,
							name: userData.name,
							userUID: userData.userUID,
						})
					);
				}
			}
		}
	});

	return (
		<IonApp>
			<IonReactRouter>
				<IonRouterOutlet>
					<Route exact path='/'>
						<Login />
					</Route>
					<Route exact path='/customer-registration'>
						<CustomerRegistration />
					</Route>
					<Route exact path='/reset-password'>
						<ResetPasswordRequest />
					</Route>
					<Route exact path='/confirm-reset-password'>
						<ConfirmResetPassword />
					</Route>
					<Route exact path='/confirm-registration'>
						<ConfirmAccountRegistration />
					</Route>
					<Route exact path='/registered-businesses'>
						<RegisteredBusinesses />
					</Route>
					<Route exact path='/registered-businesses-online'>
						<RegisteredBusinessesOnline />
					</Route>
					<Route exact path='/customer-win-list'>
						<CustomerWinList />
					</Route>
					{/* <Route exact path='/agent-login'>
						<AgentLogin />
					</Route> */}
					{/* <Route exact path='/business-home'>
						<BusinessMenu></BusinessMenu>
						<BusinessHome />
					</Route>
					<Route exact path='/business-transactions'>
						<BusinessMenu></BusinessMenu>
						<BusinessTransactions />
					</Route>
					<Route exact path='/business-scan-customer-qr'>
						<BusinessMenu></BusinessMenu>
						<BusinessScanCustomerQR />
	</Route>*/}
				</IonRouterOutlet>

				<IonTabs className={userType === UserType.CUSTOMER ? '' : 'hidden'}>
					<IonRouterOutlet>
						<Route exact path='/customer-home'>
							<CustomerMenu></CustomerMenu>
							<CustomerHome />
						</Route>
						<Route exact path='/customer-transactions'>
							<CustomerTransactions />
						</Route>
						<Route exact path='/registered-businesses'>
							<RegisteredBusinesses />
						</Route>
						<Route exact path='/registered-businesses-online'>
							<RegisteredBusinessesOnline />
						</Route>
						<Route exact path='/customer-win-list'>
							<CustomerWinList />
						</Route>
						<Route exact path='/events'>
							<EventsList />
						</Route>
						<Route exact path='/events/:id'>
							<EventDetail />
						</Route>
					</IonRouterOutlet>
					<IonTabBar slot='bottom'>
						<IonTabButton tab='CustomerHome' href='/customer-home'>
							<IonIcon icon={qrCode} />
							<IonLabel>QR</IonLabel>
						</IonTabButton>
						<IonTabButton tab='Events' href='/events'>
							<IonIcon icon={calendarOutline} />
							<IonLabel>Eventi</IonLabel>
						</IonTabButton>
						<IonTabButton
							tab='customerTransactions'
							href='/customer-transactions'
						>
							<IonIcon icon={list} />
							<IonLabel>Transazioni</IonLabel>
						</IonTabButton>
					</IonTabBar>
				</IonTabs>
				<IonTabs className={userType === UserType.BUSINESS ? '' : 'hidden'}>
					<IonRouterOutlet>
						<Route exact path='/business-home'>
							<BusinessMenu></BusinessMenu>
							<BusinessHome />
						</Route>
						<Route exact path='/business-scan-customer-qr'>
							<BusinessScanCustomerQR />
						</Route>
						<Route exact path='/business-transactions'>
							<BusinessTransactions />
						</Route>
						<Route exact path='/business-events'>
							<BusinessEvents />
						</Route>
						<Route exact path='/business-events/:id'>
							<EventDetail />
						</Route>
						<Route exact path='/new-event'>
							<AddEvent />
						</Route>
						<Route exact path='/business-choose-transaction-type'>
							<ChooseTransactionType />
						</Route>
						<Route exact path='/business-transaction-import'>
							<BusinessTransactionImport />
						</Route>
					</IonRouterOutlet>
					<IonTabBar slot='bottom'>
						<IonTabButton tab='businessHome' href='/business-home'>
							<IonIcon icon={homeOutline} />
							<IonLabel>Home</IonLabel>
						</IonTabButton>
						<IonTabButton
							tab='businessScanCustomerQR'
							href='/business-scan-customer-qr'
						>
							<IonIcon icon={qrCode} />
							<IonLabel>Scan QR</IonLabel>
						</IonTabButton>
						<IonTabButton
							tab='businessTransactions'
							href='/business-transactions'
						>
							<IonIcon icon={list} />
							<IonLabel>Transazioni</IonLabel>
						</IonTabButton>
					</IonTabBar>
				</IonTabs>
				{/* INFLUENCER */}
				<IonTabs className={userType === UserType.INFLUENCER ? '' : 'hidden'}>
					<IonRouterOutlet>
						<Route exact path='/influencer-home'>
							<InfluencerMenu></InfluencerMenu>
							<InfluencerHome />
						</Route>
						<Route exact path='/influencer-transactions'>
							<InfluencerMenu></InfluencerMenu>
							<InfluencerTransactions />
						</Route>
						{/* <Route exact path='/influencer-home'>
							<InfluencerMenu></InfluencerMenu>
							<InfluencerHome />
						</Route> */}
					</IonRouterOutlet>
					<IonTabBar slot='bottom'>
						<IonTabButton tab='InfluencerHome' href='/influencer-home'>
							<IonIcon icon={home} />
							<IonLabel>Home</IonLabel>
						</IonTabButton>
						<IonTabButton
							tab='InfluencerTransactions'
							href='/influencer-transactions'
						>
							<IonIcon icon={logoEuro} />
							<IonLabel>Entrate</IonLabel>
						</IonTabButton>
						{/* <IonTabButton tab='InfluencerHome' href='/influencer-home'>
							<IonIcon icon={list} />
							<IonLabel>QR</IonLabel>
						</IonTabButton> */}
					</IonTabBar>
				</IonTabs>
				{/* AGENT */}
				<IonTabs className={userType === UserType.AGENT ? '' : 'hidden'}>
					<IonRouterOutlet>
						<Route exact path='/agent-home'>
							<AgentMenu></AgentMenu>
							<AgentHome />
						</Route>
						<Route exact path='/agent-registered-business-list'>
							<AgentRegisteredBusinessList />
						</Route>
						<Route exact path='/agent-registered-influencer-list'>
							<AgentRegisteredInfluencerList />
						</Route>
						<Route exact path='/agent-register-business'>
							<AgentRegisterNewBusiness />
						</Route>
						<Route exact path='/agent-register-influencer'>
							<AgentRegisterNewInfluencer />
						</Route>
					</IonRouterOutlet>
					<IonTabBar slot='bottom' color='tertiary'>
						<IonTabButton tab='agentHome' href='/agent-home'>
							<IonIcon icon={homeOutline} />
							<IonLabel>Home</IonLabel>
						</IonTabButton>
						<IonTabButton
							tab='agentRegisteredBusinessList'
							href='/agent-registered-business-list'
						>
							<IonIcon icon={businessOutline} />
							<IonLabel>Attività registrate</IonLabel>
						</IonTabButton>
						{/* <IonTabButton
							tab='agentRegisteredInfluencerList'
							href='/agent-registered-influencer-list'
						>
							<IonIcon icon={bodyOutline} />
							<IonLabel>Influencers registrati</IonLabel>
						</IonTabButton> */}
					</IonTabBar>
				</IonTabs>
				<IonTabs className={userType === UserType.SUPERADMIN ? '' : 'hidden'}>
					<IonRouterOutlet>
						<Route exact path='/super-admin-home'>
							<SuperAdminMenu></SuperAdminMenu>
							<SuperAdminHome />
						</Route>
						<Route exact path='/create-agent-account'>
							<SuperAdminCreateAgentAccount />
						</Route>
						<Route exact path='/super-admin-registered-businesses-list'>
							<RegisteredBusinessesList />
						</Route>
						<Route exact path='/super-admin-registered-agents-list'>
							<RegisteredAgentsList />
						</Route>
						<Route exact path='/super-admin-registered-influencers-list'>
							<RegisteredInfluencersList />
						</Route>
						<Route exact path='/confirm-registration'>
							<ConfirmAccountRegistration />
						</Route>
						<Route exact path='/super-admin-update-business-credit'>
							<SuperAdminUpdateBusinessCredit />
						</Route>
					</IonRouterOutlet>
					<IonTabBar slot='bottom' className='hidden'>
						{/* <IonTabButton tab='businessHome' href='/business-home'>
							<IonIcon icon={homeOutline} />
							<IonLabel>Home</IonLabel>
						</IonTabButton>
						<IonTabButton
							tab='businessScanCustomerQR'
							href='/business-scan-customer-qr'
						>
							<IonIcon icon={qrCode} />
							<IonLabel>Scan QR</IonLabel>
						</IonTabButton>
						<IonTabButton
							tab='businessTransactions'
							href='/business-transactions'
						>
							<IonIcon icon={list} />
							<IonLabel>Transazioni</IonLabel>
						</IonTabButton> */}
					</IonTabBar>
				</IonTabs>
			</IonReactRouter>
		</IonApp>
	);
};

export default App;
