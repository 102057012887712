// import { SocialSharing } from '@ionic-native/social-sharing';
import {
	IonButton,
	IonButtons,
	IonCard,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonMenuButton,
	IonPage,
	IonRow,
	IonTitle,
	IonToolbar,
	useIonRouter,
} from '@ionic/react';
import {
	calendarOutline,
	checkmarkCircle,
	logoWhatsapp,
	personCircleOutline,
	shuffle,
} from 'ionicons/icons';
import { Dispatch, useEffect, useState } from 'react';
// import QRCode from 'react-qr-code';
import { QRCodeSVG } from 'qrcode.react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import { UserType } from '../../features/auth/types';
import {
	getCustomerCreditRealtime,
	getJackpotRealtime,
} from '../../services/firestore';
import { getUserData } from '../../services/storage';
import './CustomerHome.scss';
import darkBorder from '../../assets/dark-border.png';
import logo from '../../assets/logo-transp.png';
import redBorder from '../../assets/red-border.png';
import nextSunday from 'date-fns/nextSunday';
import { format } from 'date-fns/esm';
import { it } from 'date-fns/esm/locale';
import formatDuration from 'date-fns/formatDuration';
import intervalToDuration from 'date-fns/intervalToDuration';
import formatDistance from 'date-fns/formatDistance';
import getIsoWeek from 'date-fns/getISOWeek';

const CustomerHome: React.FC = () => {
	const router = useIonRouter();

	const userType = useAppSelector((state) => state.authSlice.userType);
	const [customerName, setCustomerName] = useState<string>();
	const [customerQR, setCustomerQR] = useState<string>();
	const [customerCredit, setCustomerCredit] = useState<number>(0);
	const [jackpot, setJackpot] = useState<number>();
	//const [invitationLink, setInvitationLink] = useState<string>();
	const [nextExtractionInterval, setNextExtractionInterval] = useState<any>();
	const [loaded, setLoaded] = useState<boolean>(false);

	useEffect(() => {
		// const nextExtractionTime = formatDuration(getNextExtractionTime(), {
		// 	locale: it,
		// });
		const intervalId = setInterval(() => {
			setNextExtractionInterval(getNextExtractionTime());
		}, 1000);

		return () => clearInterval(intervalId);
	}, []);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setLoaded(true);
		}, 300);

		return () => clearInterval(intervalId);
	}, [loaded]);

	const getNextExtractionTime = () => {
		const nextSundayDate = nextSunday(new Date());
		nextSundayDate.setHours(24);
		nextSundayDate.setMinutes(0);
		nextSundayDate.setSeconds(0);
		const duration = intervalToDuration({
			start: new Date().getTime(),
			end: nextSundayDate.getTime(),
		});

		return duration;
	};

	useEffect(() => {
		if (userType === UserType.CUSTOMER) {
			// setInvitationLink(
			// 	`https://wa.me/?text=Ciao! Questo è il mio invito di iscrizione a JackShop! ${process.env.REACT_APP_DOMAIN_NAME}/customer-registration?i=qFbbsd87HS56-Sgdtyy7`
			// );

			const loadUserData = async () => {
				const userData = await getUserData();

				setCustomerName(() => userData!.name);
				setCustomerQR(() => userData!.userUID);
			};

			loadUserData();

			getCustomerCreditRealtime((change) => {
				setCustomerCredit(change?.doc?.data()?.credit?.toFixed(2) || 0);
			});

			getJackpotRealtime((doc) => {
				setJackpot(doc?.data()?.amount?.toFixed(2) || 0);
			});
		} else {
			router.push('/');
		}
	}, [userType]);

	// const sendWhatsAppInvitation = () => {
	// 	SocialSharing.shareViaWhatsApp('message').then(() => {
	// 		console.log('message sent');
	// 	});
	// };

	return (
		<IonPage>
			<IonHeader className='customer-home-content'>
				<IonToolbar color='tertiary'>
					<IonTitle>Home</IonTitle>
					<IonButtons slot='start'>
						<IonMenuButton autoHide={false} menu='customerMenu'></IonMenuButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className='customer-home-content' fullscreen>
				<img className='dark-border' src={darkBorder} />
				<img className='red-border' src={redBorder} />
				<IonGrid>
					<IonRow className='ion-justify-content-center top-section'>
						<div>
							<IonRow className='ion-justify-content-center'>
								<IonCol
									size-xs='12'
									size-sm='12'
									size-md='8'
									size-lg='6'
									size-xl='6'
								>
									<IonItem>
										<IonLabel>{customerName}</IonLabel>
										<IonIcon icon={personCircleOutline} slot='start' />
									</IonItem>
									{/* <div className='user-data-wrapper'>
											<p>{customerName}</p>
										</div> */}

									<div className='qr-description'>
										<p className='this-is ion-text-center'>
											Questo è il tuo QR Code
										</p>
										<p className='ion-text-center present-it'>
											Presentalo alle attività convenzionate per partecipare
											all&apos;estrazione
										</p>
									</div>
									{!customerQR && (
										<p className='no-qr ion-text-center'>
											Codice QR non caricato
										</p>
									)}
									<div className='qr-card-wrapper'>
										<IonCard className='qr-card'>
											<QRCodeSVG
												className='qr-code'
												value={customerQR || 'noQR'}
												level='M'
												// title='QR Code'
												size={128}
											></QRCodeSVG>
										</IonCard>
									</div>
								</IonCol>
							</IonRow>
						</div>
					</IonRow>

					<IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							<div className='next-extraction-wrapper'>
								<p>La prossima estrazione avverrà tra</p>
								<p>
									<span className='time-el'>
										{nextExtractionInterval?.days}
									</span>{' '}
									giorni{' '}
									<span className='time-el'>
										{nextExtractionInterval?.hours}
									</span>{' '}
									ore{' '}
									<span className='time-el'>
										{nextExtractionInterval?.minutes}
									</span>{' '}
									minuti e{' '}
									<span className='time-el'>
										{nextExtractionInterval?.seconds}
									</span>{' '}
									secondi
								</p>
								{/* <small className='small-desc'>
									Se hai effettuato una transazione utilizzando Jackshop
									nell&apos;ultima settimana al termine dell&apos;estrazione
									puoi controllare nella sezione &apos;le tue vincite&apos; se
									sei stato il fortunato vincitore del jackpot!
								</small> */}
							</div>
							<div className='jackpot-wrapper'>
								<h1>Jackpot</h1>
								<h2>{jackpot} €</h2>
							</div>
						</IonCol>
					</IonRow>
					<IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							<div className='your-credit-wrapper'>
								<h1>Il tuo credito</h1>
								<h2>{customerCredit} €</h2>
							</div>
						</IonCol>
					</IonRow>
					{/* <IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							<IonButton
								className='whatsapp-btn'
								href={invitationLink}
								expand='full'
							>
								Invita un amico
								<IonIcon slot='start' icon={logoWhatsapp}></IonIcon>
							</IonButton>
						</IonCol>
					</IonRow> */}
					<IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							<IonButton color='primary' href={'/events'} expand='full'>
								EVENTI
								<IonIcon slot='start' icon={calendarOutline}></IonIcon>
							</IonButton>
							<div className='info-text-wrapper'>
								<small className='info-text'>
									Per maggiori informazioni consulta la pagina di supporto
								</small>
							</div>
						</IonCol>
					</IonRow>

					{/* <IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							<div className='user-data-wrapper'>
								<p>{customerName}</p>
							</div>
						</IonCol>
					</IonRow>
					<IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							{!customerQR && (
								<p className='no-qr ion-text-center'>Codice QR non caricato</p>
							)}
							<div className='qr-card-wrapper'>
								<IonCard className='qr-card'>
									<QRCode
										className='qr-code'
										value={customerQR || 'noQR'}
										level='M'
										title='QR Code'
										size={128}
									></QRCode>
								</IonCard>
							</div>
						</IonCol>
					</IonRow>
					<IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							<p className='description-text'>
								Questo è il tuo codice QR personale. Potrai utilizzarlo per
								partecipare alla vincita del jackpot e per spendere il tuo
								credito presso le attività convenzionate
							</p>
						</IonCol>
					</IonRow>
					<IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							<div className='jackpot-wrapper'>
								<h1>Il tuo credito</h1>
								<h2>{customerCredit} €</h2>
							</div>
						</IonCol>
					</IonRow>
					<IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							<div className='jackpot-wrapper'>
								<h1>Jackpot</h1>
								<h2>{jackpot} €</h2>
							</div>
						</IonCol>
					</IonRow>
					<IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							<IonButton
								className='whatsapp-btn'
								href={invitationLink}
								expand='full'
							>
								Invita un amico
								<IonIcon slot='start' icon={logoWhatsapp}></IonIcon>
							</IonButton>
						</IonCol>
					</IonRow>
					<IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							<div className='info-text-wrapper'>
								<small className='info-text'>
									Per maggiori informazioni consulta la pagina di supporto
								</small>
							</div>
						</IonCol>
					</IonRow> */}
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default CustomerHome;
