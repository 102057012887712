import { TextFieldTypes } from '@ionic/core';
import {
	IonBackButton,
	IonButton,
	IonContent,
	IonPage,
	IonRouterLink,
	useIonLoading,
	useIonRouter,
	useIonToast,
} from '@ionic/react';
import { Dispatch, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
	ERROR_CODE,
	ERROR_MESSAGE,
	PLACEHOLDER,
	REGEX,
	VALIDATION_MESSAGE,
} from '../../app/utils/constants';
import darkBorder from '../../assets/dark-border.png';
import logo from '../../assets/logo-transp.png';
import redBorder from '../../assets/red-border.png';
import CustomForm from '../../components/CustomForm/CustomForm';
import { authStateChanged } from '../../features/auth/authSlice';
import { UserType } from '../../features/auth/types';
import { loginWithEmail, requestResetPassword } from '../../services/firestore';
import { getUserData, setUserData } from '../../services/storage';
import { UserData } from '../../types';

const ResetPasswordRequest: React.FC = () => {
	const router = useIonRouter();
	const [presentLoader, dismissLoader] = useIonLoading();
	const [presentToast, dismissToast] = useIonToast();

	useEffect(() => {
		presentLoader('Attendere...', 1000);
	}, []);

	const fields = [
		{
			label: 'Email',
			props: {
				name: 'email',
				type: 'email' as TextFieldTypes,
				placeholder: PLACEHOLDER.INSERT_EMAIL,
				autocomplete: 'new-password',
			},
		},
	];

	const validationSchema = Yup.object().shape({
		email: Yup.string()
			.matches(REGEX.EMAIL, VALIDATION_MESSAGE.EMAIL_NOT_VALID)
			.required(VALIDATION_MESSAGE.REQUIRED_FIELD),
	});

	const onSubmit = async (data: any) => {
		presentLoader('Invio email in corso...');

		let userCredentials;
		try {
			userCredentials = await requestResetPassword(data.email);
			presentToast('Email di reset inviata', 2000);
		} catch (error: any) {
			if (error.code === ERROR_CODE.AUTH_USER_NOT_FOUND) {
				presentToast(ERROR_MESSAGE.EMAIL_NOT_REGISTERED, 2000);
			} else {
				presentToast(ERROR_MESSAGE.UNEXPECTED_ERROR, 2000);
			}
		} finally {
			dismissLoader();
		}
	};
	return (
		<IonPage>
			<IonContent>
				<div className='heading-logo-image-wrapper'>
					<img className='logo' src={logo} />
					<img className='dark-border' src={darkBorder} />
					<img className='red-border' src={redBorder} />
				</div>
				<div className='ion-padding'>
					<IonRouterLink href='/'>
						<IonBackButton />
						Torna al Login
					</IonRouterLink>
					<CustomForm
						fields={fields}
						validationSchema={validationSchema}
						submitLabel={'Richiedi Reset'}
						onSubmit={onSubmit}
					/>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default ResetPasswordRequest;
