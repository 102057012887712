import {
	IonBackButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonItem,
	IonLabel,
	IonList,
	IonPage,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { getRegisteredInfluencersRealtime } from '../../../services/firestore';
import './AgentRegisteredInfluencerList.scss';

const AgentRegisteredInfluencerList: React.FC = () => {
	const [influencers, setInfluencers] = useState<any[]>([]);

	useEffect(() => {
		getRegisteredInfluencersRealtime((snapshot) => {
			snapshot.docChanges().forEach((change: any) => {
				setInfluencers((prevState) => {
					const pState = [...prevState];

					if (pState.length > 0) {
						const doc = change.doc.data();
						const currentDocTime = doc.creationDate.toDate().getTime();
						const firstDocTime = pState[0].creationDate.toDate().getTime();

						if (currentDocTime > firstDocTime) {
							pState.unshift(change.doc.data());
						} else {
							pState.push(change.doc.data());
						}
					} else {
						pState.push(change.doc.data());
					}

					return pState;
				});
			});
		});
	}, []);
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>Influencers registrati</IonTitle>
					<IonButtons slot='start'>
						<IonBackButton></IonBackButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<IonList className='influencers-list-wrapper'>
					{influencers?.length === 0 && (
						<div className='no-data ion-padding ion-margin ion-text-center'>
							Non sono presenti influencers registrati
						</div>
					)}
					{influencers?.map((i) => {
						return (
							<div className='item-wrapper' key={i.creationDate.seconds}>
								<p className='header-text'>{i.name}</p>
								<IonItem
									key={i.creationDate.seconds}
									lines='full'
									className='list-item'
								>
									<IonLabel className='description'>
										<p className='text'>
											<span className='amount'>Email </span>
											{i.email}
										</p>
										<p className='text'>
											<span className='amount'>Città </span>
											{i.city}
										</p>
										<p className='text'>
											<span className='amount'>Indirizzo </span>
											{i.address}
										</p>
										<p className='text'>
											<span className='amount'>Tel. </span>
											{i.telephone}
										</p>
										<p className='text'>
											<span className='amount'>Codice Influencer </span>
											{i.influencerCode}
										</p>
										<small className='text'>
											Il{' '}
											{/* {new Intl.DateTimeFormat('it', {
												dateStyle: 'long',
												timeStyle: 'short',
											}).format(i.creationDate)} */}
											{i.creationDate}
										</small>
									</IonLabel>
								</IonItem>
							</div>
						);
					})}
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default AgentRegisteredInfluencerList;
