import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonInput,
	IonButton,
	IonItem,
	IonLabel,
	IonTextarea,
	IonSelect,
	IonSelectOption,
	useIonRouter,
	useIonToast,
	IonImg,
	IonBackButton,
	IonButtons,
	IonGrid,
	IonRow,
	IonCol,
	IonCheckbox,
	IonList,
	useIonModal,
	IonIcon,
	useIonLoading,
	useIonAlert,
} from '@ionic/react';
import './AddEvent.scss';
import {
	consumeBusinessCredit,
	createPaymentIntent,
	getCountCustomers,
	saveEvent,
} from '../../services/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useLocation } from 'react-router';
import { cashOutline, searchCircleOutline } from 'ionicons/icons';

const EventSummaryModal: React.FC<{
	location: any;
	onDismiss: () => void;
	eventDataOnWithDrawal?: any;
}> = ({ location, onDismiss, eventDataOnWithDrawal }) => {
	const query = useMemo(
		() => new URLSearchParams(location.search),
		[location.search]
	);
	let payedEvent: any;
	if (!eventDataOnWithDrawal) {
		const eventData: any = query.get('eventData');
		payedEvent = JSON.parse(decodeURIComponent(eventData));
	} else {
		payedEvent = eventDataOnWithDrawal;
	}

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Riepilogo Evento</IonTitle>
					<IonButtons slot='end'>
						<IonButton onClick={onDismiss}>Chiudi</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className='ion-padding'>
				<IonList>
					<IonItem>
						<IonLabel>Titolo</IonLabel>
						<IonLabel>{payedEvent.title}</IonLabel>
					</IonItem>
					<IonItem>
						<IonLabel>Descrizione</IonLabel>
						<IonLabel>{payedEvent.description}</IonLabel>
					</IonItem>
					<IonItem>
						<IonLabel>Categoria</IonLabel>
						<IonLabel>{payedEvent.category}</IonLabel>
					</IonItem>
					<IonItem>
						<IonLabel>Città</IonLabel>
						<IonLabel>{payedEvent.city}</IonLabel>
					</IonItem>
					<IonItem>
						<IonLabel>Indirizzo</IonLabel>
						<IonLabel>{payedEvent.address}</IonLabel>
					</IonItem>
					<IonItem>
						<IonLabel>Data Inizio</IonLabel>
						<IonLabel>{payedEvent.startDate}</IonLabel>
					</IonItem>
					<IonItem>
						<IonLabel>Data Fine</IonLabel>
						<IonLabel>{payedEvent.endDate}</IonLabel>
					</IonItem>
					<IonItem>
						<IonLabel>Stato pagamento:</IonLabel>
						<IonLabel style={{ color: 'green' }}>PAGATO</IonLabel>
					</IonItem>
					<IonItem>
						<IonLabel>Importo:</IonLabel>
						<IonLabel>
							<strong>{payedEvent.price}</strong>
						</IonLabel>
					</IonItem>
				</IonList>
			</IonContent>
		</IonPage>
	);
};

const AddEvent: React.FC = () => {
	function useQuery() {
		const { search } = useLocation();
		return useMemo(() => new URLSearchParams(search), [search]);
	}
	const [eventData, setEventData] = useState({
		title: '',
		image: null as File | null,
		imagePreview: '',
		description: '',
		category: '',
		city: '',
		address: '',
		latitude: null as number | null,
		longitude: null as number | null,
		startDate: '' as string | Date,
		endDate: '' as string | Date,
	});
	const [presentToast] = useIonToast();
	const router = useIonRouter();
	const [presentLoader, dismissLoader] = useIonLoading();
	const [storage] = useState(() => getStorage());
	const [searchQuery, setSearchQuery] = useState<string>('');
	const [suggestions, setSuggestions] = useState<any[]>([]);
	const [isSuggestionSelected, setIsSuggestionSelected] = useState(false);
	const [present, dismiss] = useIonModal(EventSummaryModal, {
		location,
		onDismiss: () => {
			window.history.replaceState({}, document.title, '/new-event');
			setEventData({
				title: '',
				image: null,
				imagePreview: '',
				description: '',
				category: '',
				city: '',
				address: '',
				latitude: null,
				longitude: null,
				startDate: '',
				endDate: '',
			});
			setSearchQuery('');
			setSuggestions([]);
			dismiss();
		},
		eventDataOnWithDrawal: eventData,
	});
	const [presentAlert] = useIonAlert();

	const debouncedSearch = useCallback(async () => {
		if (!searchQuery || !eventData.city) {
			setSuggestions([]);
			return;
		}

		const response = await fetch(
			`https://nominatim.openstreetmap.org/search?format=json&limit=5&q=${searchQuery}`
		);
		const data = await response.json();

		if (!data || data.length === 0) {
			setSuggestions([]);
			return;
		}

		setSuggestions(
			data.map((result: any) => ({
				label: result.display_name,
				value: {
					address: result.display_name,
					latitude: parseFloat(result.lat),
					longitude: parseFloat(result.lon),
				},
			}))
		);
	}, [searchQuery, eventData.city]);

	const [potentialCustomers, setPotentialCustomers] = useState<number | null>(
		null
	);
	useEffect(() => {
		if (isSuggestionSelected) {
			setIsSuggestionSelected(false);
			return;
		}
		const timer = setTimeout(debouncedSearch, 500);
		return () => clearTimeout(timer);
	}, [debouncedSearch, searchQuery, isSuggestionSelected]);

	const [loadingPotential, setLoadingPotential] = useState(false);
	const handleCountCustomers = async () => {
		setLoadingPotential(true);
		const result = await getCountCustomers(
			eventData.latitude,
			eventData.longitude,
			eventData.city
		);

		if (result.data && result.data.success) {
			console.log('Numero di clienti trovati:', result.data.count);
			setPotentialCustomers(result.data.count as number);
		} else {
			console.error('Errore nel conteggio dei clienti:', result.data?.message);
		}
		setLoadingPotential(false);
	};

	const handleSuggestionSelect = async (
		suggestion: any,
		event: React.MouseEvent
	) => {
		setEventData((prevData) => ({
			...prevData,
			city: suggestion.value.address,
			latitude: suggestion.value.latitude,
			longitude: suggestion.value.longitude,
		}));
		setSearchQuery('');
		setSuggestions([]);
		setIsSuggestionSelected(true);
		await handleCountCustomers();

		event.stopPropagation();
	};

	const handleSubmit = async (isWithDrawal: boolean, data: any) => {
		const {
			title,
			image,
			description,
			category,
			city,
			latitude,
			longitude,
			startDate,
			endDate,
			imagePreview,
		} = eventData;

		if (
			title &&
			image &&
			description &&
			category &&
			city &&
			latitude &&
			longitude &&
			startDate &&
			endDate &&
			imagePreview
		) {
			try {
				presentLoader('Operazione in corso...');

				localStorage.setItem('eventImage', JSON.stringify(imagePreview));
				localStorage.setItem('eventImageName', JSON.stringify(image.name));

				const price = calculateEventPrice(potentialCustomers || 1);
				const dataToSend = {
					title,
					description,
					category,
					city,
					latitude,
					longitude,
					startDate,
					endDate,
					price,
				};

				if (!isWithDrawal) {
					const checkoutUrl = await createPaymentIntent(dataToSend);
					window.location.href = checkoutUrl;
				} else {
					const response: any = await consumeBusinessCredit(price);
					if (!response.data?.error) {
						//presentAlert('Operazione terminata correttamente.');
						await saveEventData(true, eventData);
						//router.push('/business-home', 'root', 'push', {});
					} else {
						// Gestione degli errori specifici
						if (response.data?.error === 'credit_1/error') {
							presentAlert(
								'Credito attività non sufficiente. Contattare il supporto per una ricarica.'
							);
						} else if (response.data?.error === 'credit_2/error') {
							presentAlert(
								'Credito attività non configurato. Contattare il supporto.'
							);
						} else {
							presentAlert(
								"Errore imprevisto durante l'operazione. Riprovare più tardi."
							);
						}
					}
				}
				dismissLoader();
			} catch (error) {
				dismissLoader();
				console.error('Errore nella creazione del PaymentIntent: ', error);
				presentToast({
					message: 'Errore nella creazione del PaymentIntent',
					duration: 2000,
					position: 'top',
				});
			}
		} else {
			presentToast({
				message: 'Per favore, compila tutti i campi!',
				duration: 2000,
				position: 'top',
			});
		}
	};

	const query = useQuery();

	console.log(localStorage.getItem('eventImage'));

	const successParam = query.get('success');

	// const saveEventData = async (isWithDrawal: boolean, payedEventData?: any) => {
	// 	try {
	// 		presentLoader('Salvataggio Evento in corso...');
	// 		const eventData = query.get('eventData');
	// 		const eventImage: any = localStorage.getItem('eventImage');
	// 		const eventImageName = localStorage.getItem('eventImageName');
	// 		if (!eventData) {
	// 			throw new Error("Nessun dato dell'evento trovato nella query.");
	// 		}
	// 		if (!eventImage || eventImage === null || !eventImageName) {
	// 			throw new Error(
	// 				"Nessuna immagine dell'evento trovato nel localStorage."
	// 			);
	// 		}
	// 		const payedEvent = JSON.parse(decodeURIComponent(eventData));
	// 		if (!payedEvent) {
	// 			throw new Error("Dati dell'evento non validi nel localStorage.");
	// 		}

	// 		const image = JSON.parse(decodeURIComponent(eventImage));
	// 		if (!image) {
	// 			throw new Error(
	// 				"Nessuna immagine dell'evento trovato nel localStorage."
	// 			);
	// 		}

	// 		const base64String = image.split(',')[1];
	// 		const blob = base64ToBlob(base64String);
	// 		const imageFile = new File([blob], eventImageName, { type: blob.type });

	// 		const {
	// 			title,
	// 			description,
	// 			category,
	// 			city,
	// 			latitude,
	// 			longitude,
	// 			startDate,
	// 			endDate,
	// 		} = payedEvent;

	// 		if (latitude !== null && longitude !== null && imageFile) {
	// 			const storageRef = ref(storage, `event-images/${imageFile.name}`);
	// 			await uploadBytes(storageRef, imageFile);
	// 			const downloadURL = await getDownloadURL(storageRef);
	// 			await saveEvent({
	// 				title,
	// 				description,
	// 				image: downloadURL,
	// 				category,
	// 				city,
	// 				startDate,
	// 				endDate,
	// 				latitude,
	// 				longitude,
	// 			});

	// 			presentToast({
	// 				message: 'Evento aggiunto con successo!',
	// 				duration: 2000,
	// 				position: 'top',
	// 			});
	// 			dismissLoader();
	// 			present();

	// 			setEventData({
	// 				title: '',
	// 				image: null,
	// 				imagePreview: '',
	// 				description: '',
	// 				category: '',
	// 				city: '',
	// 				address: '',
	// 				latitude: null,
	// 				longitude: null,
	// 				startDate: '',
	// 				endDate: '',
	// 			});
	// 			setSearchQuery('');
	// 			setSuggestions([]);

	// 			localStorage.removeItem('eventImage');
	// 			localStorage.removeItem('eventImageName');
	// 		}
	// 	} catch (error) {
	// 		console.error("Errore nel salvataggio dell'evento: ", error);
	// 		presentToast({
	// 			message: "Errore nel salvataggio dell'evento",
	// 			duration: 2000,
	// 			position: 'top',
	// 		});
	// 	}
	// };

	const saveEventData = async (isWithDrawal: boolean, payedEventData?: any) => {
		try {
			presentLoader('Salvataggio Evento in corso...');

			let payedEvent;
			if (isWithDrawal && payedEventData) {
				payedEvent = payedEventData;
			} else {
				const eventData = query.get('eventData');
				if (!eventData) {
					throw new Error("Nessun dato dell'evento trovato nella query.");
				}
				payedEvent = JSON.parse(decodeURIComponent(eventData));
			}

			const eventImage: any = localStorage.getItem('eventImage');
			const eventImageName = localStorage.getItem('eventImageName');
			if (!eventImage || eventImage === null || !eventImageName) {
				throw new Error(
					"Nessuna immagine dell'evento trovato nel localStorage."
				);
			}

			const image = JSON.parse(decodeURIComponent(eventImage));
			if (!image) {
				throw new Error(
					"Nessuna immagine dell'evento trovato nel localStorage."
				);
			}

			const base64String = image.split(',')[1];
			const blob = base64ToBlob(base64String);
			const imageFile = new File([blob], eventImageName, { type: blob.type });

			const {
				title,
				description,
				category,
				city,
				latitude,
				longitude,
				startDate,
				endDate,
			} = payedEvent;

			if (latitude !== null && longitude !== null && imageFile) {
				const storageRef = ref(storage, `event-images/${imageFile.name}`);
				await uploadBytes(storageRef, imageFile);
				const downloadURL = await getDownloadURL(storageRef);
				await saveEvent({
					title,
					description,
					image: downloadURL,
					category,
					city,
					startDate,
					endDate,
					latitude,
					longitude,
				});

				presentToast({
					message: 'Evento aggiunto con successo!',
					duration: 2000,
					position: 'top',
				});
				dismissLoader();
				setEventData({
					title: '',
					image: null,
					imagePreview: '',
					description: '',
					category: '',
					city: '',
					address: '',
					latitude: null,
					longitude: null,
					startDate: '',
					endDate: '',
				});
				setSearchQuery('');
				setSuggestions([]);

				present();

				localStorage.removeItem('eventImage');
				localStorage.removeItem('eventImageName');
			}
		} catch (error) {
			console.error("Errore nel salvataggio dell'evento: ", error);
			presentToast({
				message: "Errore nel salvataggio dell'evento",
				duration: 2000,
				position: 'top',
			});
		}
	};

	const calculateEventPrice = (visitorCount: number): number => {
		if (visitorCount <= 100) return 1;
		if (visitorCount <= 500) return 2;
		if (visitorCount <= 1000) return 4;
		return 4 + Math.ceil((visitorCount - 1000) / 500) * 2;
	};

	const base64ToBlob = (base64String: any) => {
		const byteCharacters = atob(base64String);
		const byteArray = new Uint8Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteArray[i] = byteCharacters.charCodeAt(i);
		}
		const blob = new Blob([byteArray]);

		const fileType = blob.type;
		return new Blob([byteArray], { type: fileType });
	};

	useEffect(() => {
		if (successParam === 'true') {
			saveEventData(false);
		}
	}, [successParam]);

	const convertFileToBase64 = (file: File): Promise<string> => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result as string);
			reader.onerror = (error) => reject(error);
		});
	};

	const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files[0]) {
			const file = e.target.files[0];
			const base64Image = await convertFileToBase64(file);
			setEventData((prevData) => ({
				...prevData,
				image: file,
				imagePreview: base64Image,
			}));
		}
	};

	const showConfirmationAlert = async (data: any, isWithDrawal: boolean) => {
		const price = calculateEventPrice(potentialCustomers || 1);

		const alertMessage = isWithDrawal
			? `Confermi il prelievo del credito di importo ${price}€?`
			: `Confermi la transazione di importo ${price}€?`;
		presentAlert(alertMessage, [
			{
				text: 'OK',
				handler: async () => {
					handleSubmit(isWithDrawal, data);
				},
			},
			{
				text: 'Annulla',
			},
		]);
	};

	return (
		<IonPage className='add-event-page'>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>Aggiungi Evento</IonTitle>
					<IonButtons slot='start'>
						<IonBackButton></IonBackButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className='ion-padding'>
				<IonGrid>
					<IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='6'
							size-lg='6'
							size-xl='4'
						>
							<div className='form'>
								<IonItem className='form-item'>
									<IonLabel position='floating'>Titolo</IonLabel>
									<IonInput
										value={eventData.title}
										onIonChange={(e) =>
											setEventData({ ...eventData, title: e.detail.value! })
										}
										required
									/>
								</IonItem>
								<IonItem className='form-item'>
									<IonLabel position='floating'>Data Inizio</IonLabel>
									<IonInput
										type='date'
										value={eventData.startDate?.toString()}
										onIonChange={(e) =>
											setEventData({ ...eventData, startDate: e.detail.value! })
										}
										required
									/>
								</IonItem>
								<IonItem className='form-item'>
									<IonLabel position='floating'>Data Fine</IonLabel>
									<IonInput
										type='date'
										value={eventData.endDate?.toString()}
										onIonChange={(e) =>
											setEventData({ ...eventData, endDate: e.detail.value! })
										}
										required
									/>
								</IonItem>
								<IonCheckbox value='Ricorrente' title='Ricorrente' />
								<IonItem className='form-item'>
									<input
										type='file'
										accept='image/*'
										onChange={handleImageChange}
									/>
								</IonItem>
								{eventData.imagePreview && (
									<div className='image-preview'>
										<IonImg src={eventData.imagePreview} />
									</div>
								)}
								<IonItem className='form-item'>
									<IonLabel position='floating'>Descrizione</IonLabel>
									<IonTextarea
										value={eventData.description}
										onIonChange={(e) =>
											setEventData({
												...eventData,
												description: e.detail.value!,
											})
										}
										required
									/>
								</IonItem>
								<IonItem className='form-item'>
									<IonLabel>Categoria</IonLabel>
									<IonSelect
										value={eventData.category}
										onIonChange={(e) =>
											setEventData({ ...eventData, category: e.detail.value! })
										}
									>
										<IonSelectOption value='Musica'>Musica</IonSelectOption>
										<IonSelectOption value='Arte'>Arte</IonSelectOption>
										<IonSelectOption value='Tecnologia'>
											Tecnologia
										</IonSelectOption>
										<IonSelectOption value='Cibo'>Cibo</IonSelectOption>
										<IonSelectOption value='Cinema'>Cinema</IonSelectOption>
										<IonSelectOption value='Letteratura'>
											Letteratura
										</IonSelectOption>
									</IonSelect>
								</IonItem>
								<IonItem className='form-item'>
									<IonLabel position='floating'>Città</IonLabel>
									<IonInput
										value={eventData.city}
										onIonChange={(e) => {
											setEventData({ ...eventData, city: e.detail.value! });
											setSearchQuery(e.detail.value!);
										}}
										required
									/>
								</IonItem>
								{searchQuery && suggestions.length > 0 && (
									<IonList>
										{suggestions.map((suggestion) => (
											<IonItem
												key={suggestion.label}
												onClick={(e) => handleSuggestionSelect(suggestion, e)}
											>
												{suggestion.label}
											</IonItem>
										))}
									</IonList>
								)}
								{potentialCustomers !== null && (
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											color: 'white',
										}}
									>
										Potenziali visitatori:
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												width: '20px',
												height: '20px',
												borderRadius: '100%',
												background: '#1dc81d99',
											}}
										>
											{loadingPotential ? (
												<IonIcon icon={searchCircleOutline} />
											) : (
												<strong>{potentialCustomers}</strong>
											)}
										</div>
									</div>
								)}
								<div className='submit-button'>
									<IonButton
										//onClick={() => handleSubmit(false)}
										onClick={() =>
											showConfirmationAlert(
												{
													title: eventData.title,
													image: eventData.image,
													description: eventData.description,
													category: eventData.category,
													city: eventData.city,
													latitude: eventData.latitude,
													longitude: eventData.longitude,
													startDate: eventData.startDate,
													endDate: eventData.endDate,
													imagePreview: eventData.imagePreview,
												},
												false
											)
										}
									>
										Procedi al Pagamento
										<IonIcon icon={cashOutline} />
									</IonButton>
								</div>
								<div>
									<IonButton
										// onClick={() =>
										// 	history.push({
										// 		pathname: '/business-transaction-import',
										// 		state: {
										// 			qr: 'businessUID',
										// 			isCustomerWithdrawal: true,
										// 		},
										// 	})
										// }
										onClick={() =>
											showConfirmationAlert(
												{
													title: eventData.title,
													image: eventData.image,
													description: eventData.description,
													category: eventData.category,
													city: eventData.city,
													latitude: eventData.latitude,
													longitude: eventData.longitude,
													startDate: eventData.startDate,
													endDate: eventData.endDate,
													imagePreview: eventData.imagePreview,
												},
												true
											)
										}
									>
										Scala dal Credito
										<IonIcon icon={cashOutline} />
									</IonButton>
								</div>
							</div>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default AddEvent;
