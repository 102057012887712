import { TextFieldTypes } from '@ionic/core';
import {
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonPage,
	IonRow,
	IonTitle,
	IonToolbar,
	useIonAlert,
	useIonLoading,
	useIonRouter,
} from '@ionic/react';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import CustomForm from '../../components/CustomForm/CustomForm';
import {
	confirmRegistration,
	confirmResetPassword,
} from '../../services/firestore';
import darkBorder from '../../assets/dark-border.png';
import logo from '../../assets/logo-transp.png';
import redBorder from '../../assets/red-border.png';

export const ConfirmResetPassword: React.FC = (props) => {
	const [presentAlert] = useIonAlert();
	const [presentLoader, dismissLoader] = useIonLoading();
	const router = useIonRouter();

	function useQuery() {
		const { search } = useLocation();

		return useMemo(() => new URLSearchParams(search), [search]);
	}

	const query = useQuery();

	const c = query.get('c');
	const at = query.get('at');
	const email = query.get('email');
	// const token = query.get('token');
	// const oobCode = query.get('oobCode');
	const token = query.get('token');

	const fields: any = [
		// {
		// 	label: 'Email',
		// 	props: {
		// 		name: 'email',
		// 		type: 'email' as TextFieldTypes,
		// 		placeholder: 'Email',
		// 		disabled: true,
		// 		value: email,
		// 	},
		// },
		{
			label: 'Nuova Password',
			props: {
				name: 'password',
				type: 'password' as TextFieldTypes,
				placeholder: 'Nuova Password',
				autocomplete: 'new-password',
			},
		},
		{
			label: 'Conferma password',
			props: {
				name: 'confirmPassword',
				type: 'password' as TextFieldTypes,
				placeholder: 'Conferma password',
				autocomplete: 'new-password',
			},
		},
	];

	// form validation rules
	const validationSchema = Yup.object().shape({
		// email: Yup.string().required('Email obbligatoria'),
		password: Yup.string()
			.required('Password obbligatoria')
			.min(6, 'La password deve essere di almeno 6 caratteri'),
		confirmPassword: Yup.string()
			.required('Conferma password')
			.oneOf([Yup.ref('password')], 'Le password devono corrispondere'),
	});

	// const onSubmit = async (data: any) => {
	// 	const { password } = data;

	// 	presentLoader();
	// 	try {
	// 		if (c && at) {
	// 			const result: any = await confirmResetPassword(token, password);
	// 			console.log('result data ' + JSON.stringify(result));

	// 			dismissLoader();

	// 			if (!result.data.error) {
	// 				presentAlert(
	// 					'Password cambiata correttamente!\nPremi ok per andare al login',
	// 					[
	// 						{
	// 							text: 'ok',
	// 							handler: () => {
	// 								console.log('ok');
	// 								router.push('/');
	// 							},
	// 						},
	// 					]
	// 				);
	// 			} else {
	// 				if (result.data.code === 'ACCOUNT_NOT_VALID') {
	// 					presentAlert('Account non valido per il reset della password');
	// 				}
	// 			}
	// 		} else {
	// 			dismissLoader();
	// 			presentAlert('Richiesta non valida');
	// 		}
	// 	} catch (e) {
	// 		dismissLoader();
	// 		presentAlert("Errore durante l'elaborazione della richiesta");
	// 	}
	// };

	const onSubmit = async (data: any) => {
		const { password } = data;

		presentLoader();
		try {
			if (token && email && at) {
				const result: any = await confirmResetPassword(
					token,
					password,
					email,
					at
				);
				console.log('Risultato:', result);

				dismissLoader();

				if (!result.error) {
					presentAlert(
						'Password cambiata correttamente!\nPremi OK per andare al login',
						[
							{
								text: 'OK',
								handler: () => {
									console.log('OK premuto');
									router.push('/');
								},
							},
						]
					);
				} else {
					if (result.code === 'ACCOUNT_NOT_VALID') {
						presentAlert('Account non valido per il reset della password');
					}
				}
			} else {
				dismissLoader();
				presentAlert('Richiesta non valida');
			}
		} catch (e) {
			dismissLoader();
			presentAlert("Errore durante l'elaborazione della richiesta");
		}
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>Conferma Reset Password</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<div className='heading-logo-image-wrapper'>
					<img className='logo' src={logo} />
					<img className='dark-border' src={darkBorder} />
					<img className='red-border' src={redBorder} />
				</div>
				<IonGrid className='ion-margin-top'>
					<IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							<CustomForm
								fields={fields}
								validationSchema={validationSchema}
								submitLabel={'Conferma reset password'}
								onSubmit={onSubmit}
							></CustomForm>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};
