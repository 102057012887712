import {
	IonButtons,
	IonCard,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonMenuButton,
	IonPage,
	IonRow,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { bagCheckOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { getCustomerTransactionsRealtime } from '../../../services/firestore';
import './InfluencerTransactions.scss';

const InfluencerTransactions: React.FC = () => {
	const [influencerName, setInfluencerName] = useState<string>();
	const [influencerCode, setInfluencerCode] = useState();
	const [credit, setCredit] = useState<number>();

	const [transactions, setTransactions] = useState<any[]>([]);

	useEffect(() => {
		getCustomerTransactionsRealtime((snapshot) => {
			snapshot.docChanges().forEach((change: any) => {
				setTransactions((prevState) => {
					const pState = [...prevState];

					if (pState.length > 0) {
						const doc = change.doc.data();
						const currentDocTime = doc.creationDate.toDate().getTime();
						const firstDocTime = pState[0].creationDate.toDate().getTime();

						if (currentDocTime > firstDocTime) {
							pState.unshift(change.doc.data());
						} else {
							pState.push(change.doc.data());
						}
					} else {
						pState.push(change.doc.data());
					}

					return pState;
				});
			});
		});
	}, []);

	// useEffect(() => {
	// 	setInfluencerName('Gianluca Cau');
	// 	setCredit(100);
	// }, []);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>Entrate</IonTitle>
					<IonButtons slot='start'>
						<IonMenuButton
							autoHide={false}
							menu='influencerMenu'
						></IonMenuButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className='influencer-home-content ion-padding' fullscreen>
				<IonList className='transaction-list-wrapper'>
					{transactions.length > 0 &&
						transactions?.map((t) => {
							return (
								<div className='item-wrapper' key={t.creationDate.seconds}>
									<p className='header-text'>{t.businessName}</p>
									<IonItem
										key={t.creationDate.seconds}
										lines='full'
										className='list-item'
									>
										<IonIcon
											slot='start'
											icon={bagCheckOutline}
											className='icon'
										></IonIcon>
										<IonLabel className='description'>
											<p className='text'>
												<span className='amount'>Spesa </span>
												{t.amountSpent} €
											</p>
											<small className='text'>
												{new Intl.DateTimeFormat('it').format(
													t.creationDate.toDate()
												)}
											</small>
										</IonLabel>
									</IonItem>
								</div>
							);
						})}
					{transactions.length === 0 && <p>Nessuna Transazione</p>}
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default InfluencerTransactions;
