import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonPage,
	IonRow,
	IonTitle,
	IonToolbar,
	useIonRouter,
} from '@ionic/react';
import { Dispatch, useState, useEffect } from 'react';
import QrReader from 'react-qr-reader';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import darkBorder from '../../../assets/dark-border.png';
import logo from '../../../assets/logo-transp.png';
import redBorder from '../../../assets/red-border.png';
import './BusinessScanCustomerQR.scss';

const BusinessScanCustomerQR: React.FC = () => {
	const [qr, setQr] = useState('');
	const [cameraActive, setCameraActive] = useState(false);
	const dispatch: Dispatch<any> = useDispatch();
	const router = useIonRouter();
	const history = useHistory();

	useEffect(() => {
		const unlisten = history.listen((location, action) => {
			if (location.pathname !== '/business-scan-customer-qr') {
				setCameraActive(false);
			}
		});

		// Cleanup the listener on unmount
		return () => {
			unlisten();
		};
	}, [history]);

	const handleScan = (data: any) => {
		if (cameraActive) {
			if (data) {
				setQr(data);
				history.push({
					pathname: '/business-choose-transaction-type',
					state: {
						qr: data,
					},
				});
				// router.push('/business-choose-transaction-type', 'forward', 'push', {});
			}
			// return null;
		}
	};
	const handleError = (err: any) => {
		console.error(err);
	};

	useEffect(() => {
		// Turn off the camera when the component is unmounted
		return () => {
			setCameraActive(false);
			setQr('');
		};
	}, []);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>Scan QR</IonTitle>
					<IonButtons slot='start'>
						<IonBackButton />
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<div className='heading-logo-image-wrapper'>
					<img className='logo' src={logo} />
					<img className='dark-border' src={darkBorder} />
					<img className='red-border' src={redBorder} />
				</div>

				<IonGrid>
					<IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='2'
							size-xl='2'
						>
							<div className='content-wrapper ion-padding'>
								<div>
									{cameraActive ? (
										<>
											<QrReader
												delay={300}
												onError={handleError}
												onScan={handleScan}
												style={{ width: '100%' }}
											/>
											<IonButton onClick={() => setCameraActive(false)}>
												Chiudi Scan
											</IonButton>
										</>
									) : (
										<IonButton onClick={() => setCameraActive(true)}>
											Scansiona QR
										</IonButton>
									)}
								</div>
							</div>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default BusinessScanCustomerQR;
