import {
	IonButton,
	IonButtons,
	IonCard,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonMenuButton,
	IonPage,
	IonRow,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import './InfluencerHome.scss';
import { logoWhatsapp } from 'ionicons/icons';
import { getUserData } from '../../../services/storage';
import { getJackpotRealtime } from '../../../services/firestore';

const InfluencerHome: React.FC = () => {
	const [influencerName, setInfluencerName] = useState<string>();
	const [influencerCode, setInfluencerCode] = useState('');
	const [credit, setCredit] = useState<number>();
	const [invitationLink, setInvitationLink] = useState<string>();
	const [customerQR, setCustomerQR] = useState<string>();

	useEffect(() => {
		const loadUserData = async () => {
			const userData = await getUserData();
			console.log('user data ' + JSON.stringify(userData, null, 2));
			setInfluencerName(() => userData!.name);
			setInfluencerCode(() => userData!.userUID);
			setInvitationLink(
				`https://wa.me/?text=Ciao! Questo è il mio invito di iscrizione a JackShop! ${
					process.env.REACT_APP_DOMAIN_NAME
				}/customer-registration?i=${userData!.userUID}`
			);
		};

		loadUserData();
		setCredit(0); // perchè 0?
	}, []);

	// const sendWhatsAppInvitation = () => {
	// 	SocialSharing.shareViaWhatsApp('message').then(() => {
	// 		console.log('message sent');
	// 	});
	// };

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>Influencer Home</IonTitle>
					<IonButtons slot='start'>
						<IonMenuButton
							autoHide={false}
							menu='influencerMenu'
						></IonMenuButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className='influencer-home-content ion-padding' fullscreen>
				<IonGrid>
					<IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							<div className='user-data-wrapper'>
								<p>{influencerName}</p>
							</div>
						</IonCol>
					</IonRow>
					<IonRow className='qr-section ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							<p className='qr-description'>
								Questo è il tuo codice QR utilizzabile nelle attività
								convenzionate con JackShop!
								<br />
							</p>
							<div className='qr-card-wrapper'>
								<IonCard className='qr-card'>
									<QRCodeSVG
										className='qr-code'
										value={influencerCode || 'noQR'}
										level='M'
										// title='QR Code'
										size={128}
									></QRCodeSVG>
								</IonCard>
							</div>
						</IonCol>
					</IonRow>
					{/* <IonRow className='credit-section ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							<p className='credit-text'>Il tuo credito</p>
							<p className='credit-amount'>100 €</p>
						</IonCol>
					</IonRow> */}
					<IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							<IonButton
								className='whatsapp-btn'
								href={invitationLink}
								expand='full'
							>
								Invita un amico
								<IonIcon slot='start' icon={logoWhatsapp}></IonIcon>
							</IonButton>
						</IonCol>
					</IonRow>
					{/* <IonRow className='ion-justify-content-center'>
						<p className='app-info'>
							Per conoscere i tuoi vantaggi ed avere ulteriori informazioni sul
							funzionamento di JackShop clicca <a href=''>Qui</a>
						</p>
					</IonRow> */}
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};
export default InfluencerHome;
