import React, { useState, useEffect } from 'react';
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonList,
	IonItem,
	IonLabel,
	IonImg,
	IonText,
	useIonRouter,
	IonButtons,
	IonBackButton,
	IonIcon,
	IonSelect,
	IonSelectOption,
	IonSearchbar,
	IonGrid,
	IonCol,
	IonRow,
	useIonToast,
	useIonLoading,
} from '@ionic/react';
import './EventsList.scss';
import { arrowBack } from 'ionicons/icons';
import { getEvents } from '../../services/firestore';
import { BusinessEvent } from './types';
import { useHistory } from 'react-router';

const EventsList: React.FC = () => {
	const router = useIonRouter();
	const history = useHistory();
	const [events, setEvents] = useState<BusinessEvent[]>([]);
	const [currentCity, setCurrentCity] = useState('');
	const [selectedCategory, setSelectedCategory] =
		useState<string>('Tutte le categorie');
	const [searchTitle, setSearchTitle] = useState<string>('');
	const [latitude, setLatitude] = useState<number | null>(null);
	const [longitude, setLongitude] = useState<number | null>(null);
	const [presentToast] = useIonToast();
	const [presentLoader, dismissLoader] = useIonLoading();

	useEffect(() => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					setLatitude(position.coords.latitude);
					setLongitude(position.coords.longitude);
				},
				(error) => {
					console.error('Errore nella geolocalizzazione: ', error);
					presentToast({
						message: 'Errore nella geolocalizzazione',
						duration: 2000,
						position: 'top',
					});
				}
			);
		}
	}, [presentToast]);

	const fetchEvents = async () => {
		presentLoader(); // Show loader before fetching events
		try {
			const response = await getEvents(latitude, longitude, currentCity);
			console.log(response.data);

			setEvents(response.data);
		} catch (error) {
			console.error('Errore nel recupero degli eventi: ', error);
			presentToast({
				message: 'Errore nel recupero degli eventi',
				duration: 2000,
				position: 'top',
			});
		} finally {
			dismissLoader(); // Dismiss loader after fetching events (success or failure)
		}
	};

	useEffect(() => {
		fetchEvents();
	}, [
		//latitude, longitude,
		currentCity,
	]);

	const filterEvents = (event: BusinessEvent) => {
		const matchesCategory =
			selectedCategory === 'Tutte le categorie' ||
			event.category === selectedCategory;

		const matchesTitle = event.title
			.toLowerCase()
			.includes(searchTitle.toLowerCase());

		return matchesCategory && matchesTitle;
	};

	const handleEventClick = (event: BusinessEvent) => {
		history.push(`/events/${event.id}`, { event });
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Eventi</IonTitle>
					<IonItem routerLink='/customer-home' routerDirection='back'>
						<IonLabel>Torna alla Home</IonLabel>
						<IonIcon slot='start' icon={arrowBack}></IonIcon>{' '}
					</IonItem>
				</IonToolbar>
			</IonHeader>
			<IonContent className='ion-padding'>
				<IonGrid>
					<IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='6'
							size-lg='6'
							size-xl='4'
						>
							<IonToolbar>
								<IonSelect
									value={selectedCategory}
									placeholder='Seleziona categoria'
									onIonChange={(e) => setSelectedCategory(e.detail.value)}
								>
									<IonSelectOption value='Tutte le categorie'>
										Tutte le categorie
									</IonSelectOption>
									{Array.from(
										new Set(events.map((event) => event.category))
									).map((category) => (
										<IonSelectOption key={category} value={category}>
											{category}
										</IonSelectOption>
									))}
								</IonSelect>
								<IonSearchbar
									value={searchTitle}
									placeholder='Cerca per titolo'
									onIonChange={(e) => setSearchTitle(e.detail.value!)}
								/>
								<IonSearchbar
									value={currentCity}
									placeholder='Cerca per città'
									onIonChange={(e) => setCurrentCity(e.detail.value!)}
								/>
							</IonToolbar>
							<IonList>
								{events.filter(filterEvents).map((event) => (
									<IonItem
										key={event.id}
										className='event-item'
										button
										onClick={() => handleEventClick(event)}
									>
										<IonImg
											src={event.image}
											alt={event.title}
											className='event-image'
										/>
										<IonLabel className='event-details'>
											<h2>
												<strong>{event.businessName}</strong>
											</h2>
											<h2>{event.title}</h2>
											<p>{event.description}</p>
											<p>{event.city}</p>
											<IonText color='medium'>
												<small className='event-category'>
													{event.category}
												</small>
											</IonText>
										</IonLabel>
									</IonItem>
								))}
							</IonList>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default EventsList;
