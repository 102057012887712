import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonMenuButton,
	IonPage,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import darkBorder from '../../../assets/dark-border.png';
import logo from '../../../assets/logo-transp.png';
import redBorder from '../../../assets/red-border.png';
import {
	getBusinessAccountInfoRealtime,
	getJackpotRealtime,
} from '../../../services/firestore';
import './BusinessHome.scss';
import { nuclear } from 'ionicons/icons';

const BusinessHome: React.FC = () => {
	const [currentJackpotAmount, setCurrentJackpotAmount] = useState(0);
	const [businessCredit, setBusinessCredit] = useState(0);

	useEffect(() => {
		getBusinessAccountInfoRealtime((change) => {
			const document = change.doc.data();
			console.log('JSON ' + JSON.stringify(document));
			setBusinessCredit(document?.credit?.toFixed(2) || 0);
		});

		getJackpotRealtime((doc) => {
			setCurrentJackpotAmount(doc.data()?.amount?.toFixed(2) || 0);
		});
	}, []);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>Business Home</IonTitle>
					<IonButtons slot='start'>
						<IonMenuButton
							menu='businessMenu'
							id='businessMenu'
						></IonMenuButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className='business-home-content'>
				<div className='heading-logo-image-wrapper'>
					<img className='logo' src={logo} />
					<img className='dark-border' src={darkBorder} />
					<img className='red-border' src={redBorder} />
				</div>
				<div className=' jackpot-wrapper ion-margin'>
					<p className='jackpot-text'>JACKPOT</p>
					<p className='jackpot-amount-text'>{currentJackpotAmount} €</p>
				</div>

				<div className='business-credit'>
					<p className='text'>Il tuo credito JackShop</p>
					<p className='amount'>{businessCredit} €</p>
				</div>

				<div className='ion-padding'></div>
				<IonButton color='tertiary' href={'/new-event'} expand='full'>
					Inserisci Nuovo Evento
					<IonIcon slot='start' icon={nuclear}></IonIcon>
				</IonButton>
				<IonButton color='primary' href={'/business-events'} expand='full'>
					I Miei Eventi
					<IonIcon slot='start' icon={nuclear}></IonIcon>
				</IonButton>
			</IonContent>
		</IonPage>
	);
};

export default BusinessHome;
